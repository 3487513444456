import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Update } from '@ngrx/entity'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { merge, Observable, of } from 'rxjs'
import { filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import * as fromAppStatus from 'src/app/app-status/app-status.state'
import * as fromAppStatusSelectors from 'src/app/app-status/selectors/app-status.selectors'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import { CartTypeEnum, StoreTypeEnum } from 'src/app/enums/merchant-store.enums'
import { validateDate } from 'src/app/utilities/date.utilities'

import { ClearStores } from '../actions/merchant-store.actions'
import { MerchantStoreDatabase } from '../db/merchantStore.db'
import { IAmazonUpload } from '../models/iamazon-upload'
import { IApiCart, IApiReturnEditStore, IApiStore, IApiStoreUpload } from '../models/iapi-store'
import { IMerchantStoreForm } from '../models/imerchant-store-form.model'
import { IMerchantStoreCart } from '../models/imerchant-store-type.model'
import { IMerchantStoreUpload } from '../models/imerchant-store-upload.model'
import { IMerchantStore } from '../models/imerchant-store.model'
import { State as MerchantStoreState } from './../merchant-store.store'
import * as merchantStoreSelectors from './../selectors/merchant-store.selector'
import { MerchantStoreAdapter } from './../utilities/merchant-store-adapter'

@Injectable({
  providedIn: 'root',
})
export class MerchantStoreService {
  private cartsCache: Map<CartTypeEnum, IMerchantStoreCart> = new Map()
  private adapter = new MerchantStoreAdapter()
  private localDb = new MerchantStoreDatabase()

  constructor(
    private http: HttpClient,
    private appStatus: Store<fromAppStatus.State>,
    private merchantStore: Store<MerchantStoreState>
  ) {}

  public getAllStoreTypes(): Observable<IMerchantStoreCart[]> {
    if (this.cartsCache.size > 0) {
      return of(Array.from(this.cartsCache.values()))
    }

    return this.http.get('api/Cart2').pipe(
      map((carts: IApiCart[]) => carts.map((cart) => this.adapter.mapCartApiToIMerchantCart(cart))),
      tap((carts) => {
        carts.forEach((cart) => this.cartsCache.set(cart.type, cart))
      })
    )
  }

  public getStores(): Observable<[IMerchantStore[], DataSourceEnum]> {
    return merge(this.getStoresFromApiWhenOnline(), this.getStoresFromLocal())
  }

  public addStore(store: IMerchantStoreForm): Observable<IMerchantStore> {
    return this.http
      .post(
        'api/websites/savewebsite',
        this.mapObjectToFormData(this.adapter.mapFormToApiAddEditStore(store)),
        {
          headers: new HttpHeaders({
            ContentType: 'application/x-www-form-urlencoded',
          }),
        }
      )
      .pipe(
        map((result: IApiReturnEditStore) => this.adapter.mapApiEditToApiStore(result)),
        map((result: IApiStore) => this.adapter.mapStoreApiToIMerchantStore(result))
      )
  }

  public updateStore(store: IMerchantStore): Observable<IMerchantStore> {
    return this.http
      .post(
        'api/websites/savewebsite',
        this.mapObjectToFormData({ ...this.adapter.mapStoreToIAddEditStore(store) }),
        {
          headers: new HttpHeaders({
            ContentType: 'application/x-www-form-urlencoded',
          }),
        }
      )
      .pipe(
        map((result: IApiReturnEditStore) => this.adapter.mapApiEditToApiStore(result)),
        map((result: IApiStore) => this.adapter.mapStoreApiToIMerchantStore(result))
      )
  }

  public goLive(storeId: string): Observable<Update<IMerchantStore>> {
    return this.http.post('api/websites/golive', { URLID: storeId }).pipe(
      map((results: IApiStore[]) => results.find((result) => result.id.toString() === storeId)),
      map((result: IApiStore) => {
        const update: Update<IMerchantStore> = {
          id: storeId,
          changes: {
            isLive: result.isLive,
            liveDate: validateDate(result.goLiveTimestamp),
          },
        }
        return update
      })
    )
  }

  public firstGoLive$(storeId: string): Observable<IMerchantStore[]> {
    return this.http
      .post('api/websites/golive', { URLID: storeId })
      .pipe(
        map((stores: IApiStore[]) =>
          stores.map((store) => this.adapter.mapStoreApiToIMerchantStore(store))
        )
      )
  }

  public linkStore(store: IMerchantStore) {
    if (store.type === StoreTypeEnum.Shopify) {
      return this.linkShopify(store)
    }
    if (store.cart.type === CartTypeEnum.Etsy) {
      return this.linkEtsy(store)
    }
    if (store.cart.type === CartTypeEnum.SquareUp) {
      return this.linkSquareUp(store)
    }
    return this.linkOther(store)
  }

  private linkShopify(store: IMerchantStore) {
    return this.http
      .post(
        `api/websites/createShopifyConnection`,
        {
          urlid: store.id,
        },
        {
          responseType: 'text',
        }
      )
      .pipe(
        tap((goTo) => {
          if (typeof goTo !== 'string') {
            throw new Error('Bad link url')
          }
          if (goTo.length === 0) {
            throw new Error('Bad link url')
          }
        })
      )
  }

  private linkEtsy(store: IMerchantStore) {
    return this.http
      .post(`api/oauth/getauthorizationuri`, {
        urlid: store.id,
      })
      .pipe(
        tap((goTo) => {
          if (typeof goTo !== 'string') {
            throw new Error('Bad link url')
          }
          if (goTo.length === 0) {
            throw new Error('Bad link url')
          }
        })
      )
  }
  private linkSquareUp(store: IMerchantStore) {
    return this.http
      .post(`api/marketplace/getauthorizationuri_marketplacetype`, {
        urlid: store.id,
        marketplacetype: CartTypeEnum.SquareUp.toString(),
      })
      .pipe(
        tap((goTo) => {
          if (typeof goTo !== 'string') {
            throw new Error('Bad link url')
          }
          if (goTo.length === 0) {
            throw new Error('Bad link url')
          }
        })
      )
  }

  private linkOther(store: IMerchantStore) {
    return this.http
      .post(`api/marketplace/getauthorizationuri`, {
        urlid: store.id,
      })
      .pipe(
        tap((goTo) => {
          if (typeof goTo !== 'string') {
            throw new Error('Bad link url')
          }
          if (goTo.length === 0) {
            throw new Error('Bad link url')
          }
        })
      )
  }

  public disableStore(storeId: string): Observable<Update<IMerchantStore>> {
    return this.http.post('api/stores/hidestore', { id: storeId }).pipe(
      map(() => {
        const update: Update<IMerchantStore> = {
          id: storeId,
          changes: {
            disabledDate: moment().format(DateFormatEnum.SqlTime),
            isDisabled: true,
          },
        }
        return update
      })
    )
  }

  public getUploads(storeId: string, merchantId: number): Observable<IMerchantStoreUpload[]> {
    return this.http
      .post(
        'api/stores/marketplaceuploadhistory',
        {
          urlid: parseInt(storeId, 10),
          merchantid: merchantId,
        },
        {
          headers: new HttpHeaders({
            ContentType: 'application/json',
          }),
        }
      )
      .pipe(
        map((uploads: IApiStoreUpload[]) =>
          uploads.map((upload) => this.adapter.mapStoreUploadApiToStoreUpload(upload))
        )
      )
  }

  public getPendingAmazonUploades(storeId: string): Observable<IAmazonUpload[]> {
    return this.http.post<IAmazonUpload[]>(
      'api/websites/gettaxreports',
      this.mapObjectToFormData({
        urlID: storeId,
        reportID: 0,
      }),
      {
        headers: new HttpHeaders({
          ContentType: 'application/x-www-form-urlencoded',
        }),
      }
    )
  }

  public triggerAmazonUpload(reportId: string, storeId: string) {
    return this.http.post('api/websites/UploadTaxReports2', {
      reportId,
      urlid: storeId,
    })
  }

  public deleteCache(): void {
    this.merchantStore.dispatch(new ClearStores())
    this.localDb.clearStores().pipe(first()).subscribe()
  }

  private getStoresFromApiWhenOnline(): Observable<[IMerchantStore[], DataSourceEnum]> {
    return this.appStatus.select(fromAppStatusSelectors.selectOnline).pipe(
      filter((online) => online),
      switchMap(() => this.getStoresFromApi())
    )
  }

  public getStoresFromApi(): Observable<[IMerchantStore[], DataSourceEnum]> {
    return this.http.get('api/stores').pipe(
      map((stores: IApiStore[]) =>
        stores.map((store) => this.adapter.mapStoreApiToIMerchantStore(store))
      ),
      tap((stores: IMerchantStore[]) => this.localDb.saveStores(stores)),
      map((stores) => [stores, DataSourceEnum.API])
    )
  }

  public getIntegrationByMarketPlaceIdFromApi(marketPlaceId: string | null): Observable<IApiStore> {
    return this.http.get<IApiStore[]>('api/stores').pipe(
      map((stores: IApiStore[]) => {
        return stores.reverse().find((store) => store.marketplaceID === marketPlaceId)
      })
    )
  }

  private getStoresFromLocal(): Observable<[IMerchantStore[], DataSourceEnum]> {
    return this.localDb.getStores().pipe(
      withLatestFrom(this.merchantStore.select(merchantStoreSelectors.selectDataSource)),
      filter(([locations, source]) => {
        if (source === DataSourceEnum.API) {
          return false
        }
        if (locations.length === 0) {
          return false
        }
        return true
      }),
      map(([locations, _]) => [locations, DataSourceEnum.IndexedDB])
    )
  }

  private mapObjectToFormData(obj: object): FormData {
    const form = new FormData()

    for (const [key, value] of Object.entries(obj)) {
      form.set(key, value.toString())
    }
    return form
  }
}
